import React from 'react';

function LdsLoader({scale,thick,color}) {
    if (!scale) {
        scale = 32;
    }
    if (!thick) {
        thick = 4;
    }
    if (!color){
        color = '#ffffff';
    }

    return (
        <div className="lds-loader">
            <div className="lds-ring" style={{width: `${scale}px`, height: `${scale}px`}}>
                <div style={{borderWidth: `${thick}px`,borderColor:`${color} transparent transparent transparent`}}/>
                <div style={{borderWidth: `${thick}px`,borderColor:`${color} transparent transparent transparent`}}/>
                <div style={{borderWidth: `${thick}px`,borderColor:`${color} transparent transparent transparent`}}/>
                <div style={{borderWidth: `${thick}px`,borderColor:`${color} transparent transparent transparent`}}/>
            </div>
        </div>

    );
}

export default LdsLoader;
