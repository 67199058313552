import React, {useState, Fragment, Component} from 'react';
import Page from "../Components/Page";
import gold_badge from '../Assets/images/gold_badge.png'
import silver_badge from '../Assets/images/silver_badge.png'
import bronze_badge from '../Assets/images/bronze_badge.png'
import Badge from "../Components/Badge";
import {FacebookInstanGame} from "../Services/FacebookInstanGame";
import LdsLoader from "../Components/LdsLoader";
import {GetRequest, PostRequest} from "../Helpers/Http/Request";
import {CreateMarkup} from "../Components/CreateMarkup";


export default class HomeFacebook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            showFullText: false,
            badges: [],
            home: {
                content: '',
                excerpt: '',
            }
        };
        this.init = this.init.bind(this);
        this.updateState = this.updateState.bind(this);
        this.startGame = this.startGame.bind(this);
        this.toggleFullText = this.toggleFullText.bind(this);
    }

    async componentDidMount() {
        const request = this.init();
        await request;
    }

    updateState(state) {
        return new Promise((resolve) => {
            this.setState(state, () => {
                resolve();
            });
        });
    }

    init() {
        return window['FBInstant'].initializeAsync().then(() => {
            if (!this.props.locale.changedLocale){
                this.props.locale.setLang(window['FBInstant'].getLocale());
            }

            this.startGame();
            window['FBInstant'].setLoadingProgress(100);

        }).catch((thrown) => {
            this.startGame();
            console.log('thrown', thrown)
        });
    }

    startGame() {
        // Now that assets are loaded, call startGameAsync
        window['FBInstant'].startGameAsync().then(() => {
            this.props.setPlayer({
                    id: window['FBInstant'].player.getID(),
                    photo: window['FBInstant'].player.getPhoto(),
                    name: window['FBInstant'].player.getName(),
                    type: window['FBInstant'].context.getType(),
                    locale: window['FBInstant'].getLocale(),
                }
            ).then(() => {
                return GetRequest('/quiz/get-home', {
                    tkn: window.selfLocalStorage.getItem('tkn'),
                }, {
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        if (totalLength !== null) {
                            window['FBInstant'].setLoadingProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                        }
                    }
                }).then((response) => {
                    window.selfLocalStorage.setItem('tkn', response.data.tkn)
                    window['FBInstant'].setLoadingProgress(100);
                    this.updateState({
                        initialized: true,
                        home: response.data.home,
                        badges: response.data.badges,
                    }).then(() => {
                    })
                });
            });

        }).catch((thrown) => {
            console.log('thrown', thrown)
        });
    }

    toggleFullText() {
        this.updateState({
            showFullText: !this.state.showFullText
        })
    }

    render() {

        return (
            <Page locale={this.props.locale}>
                <button className={`change-lang`} onClick={(e) => {
                    e.preventDefault();
                    if (this.props.locale.language === 'ar') {
                        this.props.changeLocale('en');
                    } else {
                        this.props.changeLocale('ar');
                    }
                }}>
                    {
                        this.props.locale.language === 'ar' ? 'English' : 'عربي'
                    }
                </button>
                {
                    !this.state.initialized ? <LdsLoader scale={100} thick={10}/> : (<Fragment>
                        <div className="quiz_desc">
                            {
                                !this.state.home.excerpt.length ? CreateMarkup(this.state.home.content) : <Fragment>
                                    {(this.state.showFullText ? CreateMarkup(this.state.home.content) : CreateMarkup(this.state.home.excerpt))}
                                    <span className="quiz_desc_more" onClick={this.toggleFullText} onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            this.toggleFullText();
                                        }
                                    }} tabIndex={0}>
                            {
                                !this.state.showFullText ? this.props.locale.get('show_more_text') : this.props.locale.get('show_less_text')
                            }
                            </span>
                                </Fragment>
                            }
                        </div>
                        <div className="quiz_more center">
                            <button type={'button'} className="main-btn" onClick={(e) => {
                                e.preventDefault();
                                this.props.showQuiz();
                            }}>
                                {this.props.locale.get('start_quiz')}
                            </button>
                        </div>
                        <div className="item1-w mt40">
                            <div className="row jcc">
                                {
                                    this.state.badges.map((badge) => (
                                        <div key={`badge-${badge.id}`} className="col-xl-3 col-lg-4 col-sm-6 d-flex">
                                            <Badge imageSrc={badge.image} title={badge.title} desc={badge.content}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Fragment>)
                }

            </Page>
        );
    }
}

