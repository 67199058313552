// const _originUrls = {};

let _API_URI = {
    URI: 'https://www.hrquiz.ps/',
    REST: 'https://www.hrquiz.ps/platform/api/v1/',
};

export const isFbApp = () => {
    return !(typeof window.application !== 'undefined' && typeof window.application.selfHosted !== 'undefined' && window.application.selfHosted === true);
}

export default {
    app_name: 'اختبار حقوق الإنسان',
    URI: document.location.origin + '/',
    api: _API_URI,
    social: {
        facebook: {
            app_id: '413742642974852',
            game_link: 'https://fb.gg/play/human_rights_quiz',
        },
    },
    googleAnalytics: {
        id: 'G-LLDCG5L31G',
    },
}
