import React, {Component, useState} from 'react';
import './Assets/styles/index.css';
import Home from "./Controllers/Home";
import HomeFacebook from "./Controllers/HomeFacebook";
import ErrorBoundary from "./Support/ErrorBoundary";
import Quiz from "./Controllers/Quiz";
import {isFbApp} from "./AppConfig";
import LdsLoader from "./Components/LdsLoader";

class App extends Component {
    constructor(props) {
        super(props);

        let player = {
            id: '',
            photo: '',
            name: '',
            type: 'self_hosted',
            completed_quiz: false,
            locale: window['application'].language || 'en',
        };
        if (typeof window['application'] !== 'undefined' && typeof window['application'].player !== 'undefined') {
            player = window['application'].player;
            if (typeof window['application'].player.token !== 'undefined') {
                window.selfLocalStorage.setItem('tkn', window['application'].player.token);
            }
        }
        this.state = {
            showQuiz: false,
            player: player,
            changingLang: false,
        };
        this.showQuiz = this.showQuiz.bind(this);
        this.setPlayer = this.setPlayer.bind(this);
        this.changeLocale = this.changeLocale.bind(this);
    }

    showQuiz() {
        this.setState({showQuiz: true});
    }

    setPlayer(player) {
        return new Promise((resolve) => {
            this.setState({player: player}, () => {
                resolve();
            });
        })
    }

    changeLocale(code) {
        this.setState({
            changingLang: true
        }, () => {
            this.props.locale.setLang(code);
            this.setState({
                changingLang: false,
            })
        })
    }

    render() {
        return (
            <div>
                <ErrorBoundary>
                    {
                        this.state.changingLang ? <LdsLoader scale={100} thick={10}/> : (this.state.showQuiz ?
                            <Quiz player={this.state.player} locale={this.props.locale}/> : (isFbApp() ?
                                <HomeFacebook locale={this.props.locale}
                                              changeLocale={this.changeLocale}
                                              showQuiz={this.showQuiz}
                                              setPlayer={this.setPlayer}
                                              player={this.state.player}/> :
                                <Home locale={this.props.locale}
                                      changeLocale={this.changeLocale}
                                      showQuiz={this.showQuiz}
                                      setPlayer={this.setPlayer}
                                      player={this.state.player}/>))
                    }

                </ErrorBoundary>
            </div>
        );
    }
}

export default App;
