import React, {Component, Fragment} from 'react';

const stars = (total) => {
    let arr = [];
    for (let i = 0; i < total; ++i) {
        arr.push(<i key={`start-${i}`} className="icon-star"/>);
    }
    return arr;
}

function QuizQuestion({question, onSelectAnswer, didSelectedAnswer,locale,stepId}) {
    let answers = question.answers || [];

    return (
        <div className="question">
            <div className="question-head">
                <div className="question_title">
                    <span className="question_num">{stepId}</span>
                    {question.title}
                </div>
                <div className="question_info">
                    <span className="question_note">
                        {question.is_multi_ans ? locale.get('multiple_correct_answers') : ''}

                    </span>
                    <div className="question_num_stars">
                        {locale.get('total_question_score')}
                        {stars(question.stars)}
                    </div>

                </div>
            </div>
            <div className="answer">
                <ul className="answer_list">
                    {
                        answers.map((answer) => (
                            <li key={`answer-${answer.id}`} onClick={onSelectAnswer.bind(this, answer.id)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        onSelectAnswer(answer.id, e);
                                    }
                                }} tabIndex={0}>
                                <div className="check">
                                    {/*<input id={`answer-${answer.id}`} type="radio" name="answer" value={answer.id}*/}
                                    {/*       onClick={onSelectAnswer.bind(this, answer.id)}*/}
                                    {/*       className="check-input"/>*/}

                                    <label htmlFor={`answer-${answer.id}`}
                                           className={`check-label ${didSelectedAnswer(answer.id) ? 'is-checked' : ''}`}>
                                        {answer.title}
                                        <span className="check-checkmark" >{answer.letter}</span>
                                    </label>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default QuizQuestion;
