export default {
    quiz_title: 'إختبار حقوق الإنسان',
    show_more_text: 'إقرأ المزيد',
    show_less_text: 'عرض أقل',
    start_quiz: 'ابدأ الإختبار',
    next_quest: 'التالي',
    show_results: 'أظهر النتيجة',
    total_question_score: 'مجموع نقاط السؤال',
    multiple_correct_answers: '"عدة إجابات صحيحة"',
    try_again_to_get_more_points: 'حاول مجدداُ للحصول على نقاط اكثر!',
    share_using: 'شارك عبر',
    support_human_rights: 'ادعم حقوق الإنسان!',
    congratulations: 'مبروك',
    share_your_achievement_and_challenge_your_friends: 'شارك انجازك وتحدى أصدقاءك',
    thank_you_for_taking_this_short_quiz_and_for_being_part_of_international_human_rights_day: 'شكرا لمشاركتك في هذا الإختبار القصير ولكونك جزء من اليوم العالمي لحقوق الإنسان!',
    quiz_final_result: `لقد حصلت على مجموع %score% من 20 نقطة، وفزت بوسام.`,
    the_correct_answer_is: `الجواب الصحيح هو`,
    the_other_correct_answer_is: `الإجابة الصحيحة الأخرى هي`,
    this_is_the_correct_answer: `هذه هي الإجابة الصحيحة`,
    this_is_one_of_the_correct_answers: `هذه واحدة من الإجابات الصحيحة`,
    challenge_your_friends: `تحدى اصدقائك`,
    all_answers_are_correct: `كل الإجابات صحيحة`,
}