import React, {Fragment} from 'react';
import AppConfig, {isFbApp} from "../AppConfig";
import {isMobile} from "react-device-detect";
import '../Assets/styles/mock/mock.css';

const getBase64 = (file) => {
    return new Promise((resolve) => {
        let img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = file;
        img.onload = function () {
            let canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL("image/jpeg");
            dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            resolve(dataURL);
        }
    })

};

function Sharing({userReward, locale}) {

    return (
        <Fragment>
            <div className="share">
                <div className="share_title">{locale.get('share_using')}:</div>
                <ul className="share_buttons">
                    <li>
                        <button onClick={(e) => {
                            e.preventDefault();
                            if (!isFbApp()) {
                                window['FB'].ui({
                                    method: 'share',
                                    href: userReward.share_link,
                                }, function (response) {
                                });
                                return;
                            }
                            getBase64(userReward.facebook_reward)
                                .then((imgBase64) => {
                                    window['FBInstant'].shareAsync({
                                        intent: 'SHARE',
                                        image: imgBase64,
                                        text: locale.get('quiz_title'),
                                        data: {myReplayData: 'message sent...'},
                                    }).then(function (response) {
                                        console.log('SHARE@response', response);
                                    }).catch(() => {
                                        window['FB'].ui({
                                            method: 'share',
                                            href: userReward.share_link,
                                        }, function (response) {
                                        });
                                    });
                                })

                        }} className="share_icon facebook">
                            <i className="icon-facebook"/>
                        </button>
                    </li>
                    <li>
                        <a href={`${isMobile ? 'whatsapp://send' : 'https://wa.me/'}?text=${AppConfig.app_name} ${userReward.share_link}`}
                           target={'_blank'} className="share_icon whatsapp">
                            <i className="icon-whatsapp"/>
                        </a>
                    </li>
                </ul>
            </div>
            <button className="challenge_icon" onClick={(e) => {
                e.preventDefault();
                if (!isFbApp()) {
                    window['FB'].ui({
                        method: 'share',
                        href: userReward.app_link,
                    }, function (response) {
                    });
                    return;
                }

                getBase64(userReward.app_og_image)
                    .then((imgBase64) => {
                        window['FBInstant'].shareAsync({
                            intent: 'INVITE',
                            image: imgBase64,
                            text: locale.get('quiz_title'),
                            data: {myReplayData: 'message sent...'},
                        }).then(function (response) {
                            console.log('INVITE@response', response);
                        });

                    })
            }}>
                {locale.get('challenge_your_friends')}
            </button>
        </Fragment>
    );
}

export default Sharing;
