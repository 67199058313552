import React from 'react';
import {CreateMarkup} from './CreateMarkup';

function Badge({imageSrc, title, desc}) {
    return (
        <div className="item1">
            <div className="item1-image">
                <img src={imageSrc} alt={title}/>
            </div>
            <div className="item1-title">{title}</div>
            <div className="item1-desc">
                {CreateMarkup(desc)}
            </div>
        </div>
    );
}

export default Badge;
