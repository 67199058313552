import React, {Component} from 'react';

export class Popup extends Component {

    render() {
        const {isVisible} = this.props;

        return (
            <div className={`popup__1 ${isVisible ? 'is-visible' : false}`}>
                <div className="popup__1--main-body">
                    <div className="popup__1--container">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
