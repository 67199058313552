import React from 'react';
import logo from '../Assets/images/logo.png';
import enLogo from '../Assets/images/en-logo.png';

function Page(props) {
  return (
    <div className="content">
      <div className={'quiz'}>
        <div className="container">
          <div className="quiz-head">
            <div className="quiz_logo">
              <img src={props.locale.language == 'ar' ? logo : enLogo} alt={props.locale.get('quiz_title')} />
            </div>
            <div className="quiz_title">{props.locale.get('quiz_title')}</div>
          </div>
          <div className={`quiz_content ${props.contentClassName ? props.contentClassName : ''}`}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
