export default class SelfLocalStorage {
    constructor() {
        this.storage = {}
    }

    setItem(key, value, callback) {
        return new Promise((resolve, reject) => {
            this.storage[key] = value
            // eslint-disable-next-line no-console
            // console.debug('setItem called with ', key, value)
            if (callback) callback(null, value)
            resolve(value)
        })
    }

    getItem(key, callback) {
        const value = this.storage[key] || null;
        if (callback) {
            callback(null, value);
        }
        return value;
    }

    getItemAsync(key, callback) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-console
            // console.debug('getItem called with ', key)
            const value = this.storage[key]
            if (callback) callback(null, value)
            resolve(value)
        })
    }

    removeItem(key, callback) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-console
            // console.debug('removeItem called with ', key)
            const value = this.storage[key]
            delete this.storage[key]
            if (callback) callback(null, value)
            resolve(value)
        })
    }

    getAllKeys(callback) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-console
            // console.debug('getAllKeys called')
            const keys = Object.keys(this.storage)
            if (callback) callback(null, keys)
            resolve(keys)
        })
    }
}
