import axios from 'axios';

export default (thrown) => {
    let errors = {};
    if (axios.isCancel(thrown)) {

    } else if (thrown.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errors = thrown.response;
        if (typeof thrown.response.data !== 'undefined') {
            errors = thrown.response.data;
        }
    } else if (thrown.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errors = thrown.request;
    } else {
        // Something happened in setting up the request that triggered an Error
        errors = thrown.message;
    }
    if (process.env.NODE_ENV === 'development') {
        console.error(thrown, errors);
    }
    return errors;
}