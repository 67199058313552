import axios, {CancelToken} from "axios";
import Config from "../../AppConfig";

// var assert = chai.assert;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

// axios.defaults.headers.common['Authorization'] = 'Bearer: ';
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

const toQueryString = (obj, prefix) => {
    let str = [], k, v;
    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        } // skip things from the prototype
        if (~p.indexOf('[')) {
            k = prefix ? prefix + "[" + p.substring(0, p.indexOf('[')) + "]" + p.substring(p.indexOf('[')) : p;
// only put whatever is before the bracket into new brackets; append the rest
        } else {
            k = prefix ? prefix + "[" + p + "]" : p;
        }
        v = obj[p];
        str.push(typeof v === "object" ?
            toQueryString(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
    str = str.join("&").substring(0);

    return str;
}

export const GetRequest = (url, data = {}, config = {}) => {
    if (!data) {
        data = {};
    }
    data['ts'] = new Date().getTime();
    let params = {};
    if (config.isUpload && config.isUpload === true) {
        params = data;
    } else {
        params = data;
    }

    if (!config.cancelToken) {
        config.cancelToken = new CancelToken(function (c) {
            return c;
        });
    }

    let urlConcat = '?';
    if (url.indexOf('?') !== -1) {
        urlConcat = '&';
    }
    params['locale'] = window['application'].language;

    url = `${url}${urlConcat}${toQueryString(params)}`;
    return axios({
        method: 'get',
        baseURL: Config.api.REST,
        url: url,
        data: params,
        withCredentials: true,
        crossDomain: true,
        responseType: 'json',
        // transformRequest: [transformRequest],
        transformResponse: [function (data, headers) {
            // console.log('transformResponse', data)
            if (typeof data === 'object' && typeof headers === 'object' && headers["content-type"] && headers["content-type"].indexOf('application/json') !== -1) {
                try {
                    data = JSON.parse(data);
                } catch (err) {
                    // data = {
                    //     status: false,
                    // };
                }
            } else {
                data = {
                    status: false,
                };
            }
            return data;
        }],
        ...config
    });
}

export const PostRequest = (url, data = {}, config = {}) => {
    if (!data) {
        data = {};
    }
    data['ts'] = new Date().getTime();
    let params = {};

    if (config.isUpload && config.isUpload === true) {
        params = data;
    } else {
        params = data;
    }

    if (!config.cancelToken) {
        config.cancelToken = new CancelToken(function (c) {
            return c;
        });
    }

    // return encryptJwe(params).then((token) => {

    if (params instanceof FormData === true) {
        // params.append('_vdata', token);
        params.append('_vpr', 1);
    } else {

    }

    params['locale'] = window['application'].language;

    if (typeof data['tkn'] === 'undefined'){
        data['tkn'] = window.selfLocalStorage.getItem('tkn') || '';
    }
    return axios({
        method: 'post',
        baseURL: Config.api.REST,
        url: url,
        data: params,
        withCredentials: true,
        crossDomain: true,
        responseType: 'json',
        transformResponse: [function (data, headers) {
            if (typeof data === 'object' && typeof headers === 'object' && headers["content-type"].indexOf('application/json') !== -1) {
                try {
                    data = JSON.parse(data);
                } catch (err) {
                }
            } else {
                data = {
                    status: false,
                };
            }

            return data;
        }],
        ...config
    });
}
