import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    // static getDerivedStateFromError(error) {
    //     console.error('getDerivedStateFromError',error)
    //     // Update state so the next render will show the fallback UI.
    //     this.setState({
    //         hasError: true,
    //         error: error,
    //     })
    // }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        // console.error('componentDidCatch',error,errorInfo);
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        })

    }

    render() {
        if (this.state.hasError || this.state.errorInfo) {
            // You can render any custom fallback UI
            return <div>
                <div>Something went wrong.</div>
                {/*<details style={{ whiteSpace: 'pre-wrap' }}>*/}
                {/*    {this.state.error && this.state.error.toString()}*/}
                {/*    <br />*/}
                {/*    {this.state.errorInfo.componentStack}*/}
                {/*</details>*/}
            </div>;
        }

        return this.props.children;
    }
}
