export default {
    quiz_title: 'Human Rights Quiz',
    show_more_text: '...Show more',
    show_less_text: 'Show less',
    start_quiz: 'Start the quiz',
    next_quest: 'Next',
    show_results: 'Show result',
    total_question_score: 'Question score',
    multiple_correct_answers: '"Multiple  correct answers"',
    try_again_to_get_more_points: 'Try again to get a better score!',
    share_using: 'Share',
    support_human_rights: 'Stand Up for Human rights!',
    congratulations: 'Congratulations',
    share_your_achievement_and_challenge_your_friends: 'Share your achievement and challenge your friends.',
    thank_you_for_taking_this_short_quiz_and_for_being_part_of_international_human_rights_day: 'Thank you for playing the Quiz and being a part of International Human Rights Day!',
    quiz_final_result: `You have received a total score of %score% out of 20 points, and won the badge`,
    the_correct_answer_is: `The correct answer is`,
    the_other_correct_answer_is: `The other correct answer is`,
    this_is_the_correct_answer: `This is the correct answer`,
    this_is_one_of_the_correct_answers: `This is one of the correct answers`,
    challenge_your_friends: `Challenge your friends`,
    all_answers_are_correct: `All answers are correct`,
}