import arPhrases from './Messages/ar';
import enPhrases from './Messages/en';

let defaultLocale = window['application'].language || 'en';
const phrases = {
    ar: {...arPhrases},
    en: {...enPhrases},
    en_US: {...enPhrases},
};

export const Locale = {

    language: defaultLocale,
    phrases: phrases[defaultLocale],
    changedLocale: false,
    setLang: function (code) {
        if (code.indexOf('ar_') === 0) {
            code = 'ar';
        } else if (code.indexOf('en_') === 0) {
            code = 'en';
        }

        this.changedLocale = true;

        if (typeof phrases[code] === 'undefined') {
            code = 'en';
        }
        window['application'].language = code;
        this.language = code;
        this.phrases = phrases[code];
        let dir = 'ltr';
        if (code == 'ar'){
            dir = 'rtl';
        }
        const dom = document.getElementsByTagName('html')[0];
        dom.setAttribute('dir',dir)
    },
    get: function (name, placeholders = {}) {
        const arr = name.split('.');
        let occurrence = null;
        arr.forEach((k) => {
            if (!occurrence) {
                if (typeof this.phrases === 'undefined' || typeof this.phrases[k] === 'undefined'){
                    occurrence = name;
                }else{
                    occurrence = this.phrases[k] || name;
                }
            } else {
                occurrence = occurrence[k] || name;
            }
        });
        if (typeof placeholders === 'object') {
            if (typeof occurrence === 'function') {
                return occurrence(placeholders);
            } else {
                Object.keys(placeholders).forEach(function (key) {
                    occurrence = occurrence.replace(new RegExp('%' + key + '%'), placeholders[key]);
                });
            }
        }
        return occurrence;
    }
}