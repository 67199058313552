import React, {useState, Fragment, Component} from 'react';
import Page from "../Components/Page";
import Badge from "../Components/Badge";
import LdsLoader from "../Components/LdsLoader";
import {GetRequest, PostRequest} from "../Helpers/Http/Request";
import {CreateMarkup} from "../Components/CreateMarkup";


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            showForm: false,
            showFullText: false,
            badges: [],
            home: {
                content: '',
                excerpt: '',
            },
            player: {
                id: 0,
                photo: '',
                name: '',
                type: '',
            },
        };
        this.init = this.init.bind(this);
        this.updateState = this.updateState.bind(this);
        this.startGame = this.startGame.bind(this);
        this.toggleFullText = this.toggleFullText.bind(this);
        // this.form = this.form.bind(this);
    }

    async componentDidMount() {
        const request = this.init();
        await request;
    }

    updateState(state) {
        return new Promise((resolve) => {
            this.setState(state, () => {
                resolve();
            });
        });
    }

    init() {
        return this.startGame();
    }

    startGame() {
        this.props.locale.setLang(window['application'].language);

        return GetRequest('/quiz/get-home', {
            tkn: window.selfLocalStorage.getItem('tkn'),
        }, {
            onUploadProgress: (progressEvent) => {
                if (typeof window['FBInstant'] !== 'undefined') {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        window['FBInstant'].setLoadingProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                    }
                }
            }
        }).then((response) => {
            this.updateState({
                initialized: true,
                home: response.data.home,
                badges: response.data.badges,
            }).then(() => {
            })

        });
    }

    toggleFullText() {
        this.updateState({
            showFullText: !this.state.showFullText
        })
    }

    form() {
        // return <Popup isVisible={this.state.showForm || false}>
        //     <PopupHead>
        //         <div className="popup__1--title">
        //             يرجى إدخال إسمك
        //         </div>
        //     </PopupHead>
        //     <PopupBody>
        //         <div className="popup__group">
        //             <div className="popup__1--body--desc">
        //                 <input type="text" className={'input-control text-center'}
        //                        maxLength={70}
        //                        onChange={(e) => {
        //                            const player = this.props.player || {};
        //                            player.name = e.target.value;
        //                            this.props.setPlayer(player);
        //                        }}
        //                        defaultValue={this.props.player.name}/>
        //             </div>
        //         </div>
        //     </PopupBody>
        //
        //     <PopupFooter>
        //         <button type="button"
        //                 className={`popup__form-btn ${this.props.player.name.length < 3 ? 'disabled' : ''}`}
        //
        //                 onClick={(e) => {
        //                     e.preventDefault();
        //                     this.props.showQuiz();
        //                 }}>
        //             <span
        //                 className="popup__form-btn__title">ابدأ الاختبار</span>
        //         </button>
        //     </PopupFooter>
        // </Popup>
    }


    render() {

        return (
            <Page locale={this.props.locale}>
                {
                    !this.state.initialized ? <LdsLoader scale={100} thick={10}/> : (<Fragment>
                        <div className="quiz_desc">
                            {
                                !this.state.home.excerpt.length ? CreateMarkup(this.state.home.content) : <Fragment>
                                    {(this.state.showFullText ? CreateMarkup(this.state.home.content) : CreateMarkup(this.state.home.excerpt))}
                                    <span className="quiz_desc_more" onClick={this.toggleFullText} onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            this.toggleFullText();
                                        }
                                    }} tabIndex={0}>
                            {
                                !this.state.showFullText ? this.props.locale.get('show_more_text') : this.props.locale.get('show_less_text')
                            }
                            </span>
                                </Fragment>
                            }
                        </div>
                        <div className="quiz_more center">
                            <button type={'button'} className="main-btn" onClick={(e) => {
                                e.preventDefault();
                                // this.updateState({
                                //     showForm: true,
                                // });
                                //
                                this.props.showQuiz();
                            }}>
                                {this.props.locale.get('start_quiz')}
                            </button>
                        </div>
                        <div className="item1-w mt40">
                            <div className="row jcc">
                                {
                                    this.state.badges.map((badge) => (
                                        <div key={`badge-${badge.id}`} className="col-xl-3 col-lg-4 col-sm-6 d-flex">
                                            <Badge imageSrc={badge.image} title={badge.title} desc={badge.content}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Fragment>)
                }

            </Page>
        );
    }
}

