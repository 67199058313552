import React, {Component, Fragment} from 'react';
import gold_badge from "../Assets/images/gold_badge.png";
import bronze_badge from "../Assets/images/bronze_badge.png";
import silver_badge from "../Assets/images/silver_badge.png";

import hrLogo from "../Assets/images/logo1.png";
import hrEnLogo from "../Assets/images/en-logo.png";
import unLogo from "../Assets/images/unlogo.jpg";

import Page from "../Components/Page";
import LdsLoader from "../Components/LdsLoader";
import {PostRequest} from "../Helpers/Http/Request";
import ErrorHandler from "../Helpers/Http/ErrorHandler";
import QuizQuestion from "../Components/Quiz/QuizQuestion";
import {Popup} from "../Components/Popup/Popup";
import PopupHead from "../Components/Popup/PopupHead";
import PopupBody from "../Components/Popup/PopupBody";
import PopupFooter from "../Components/Popup/PopupFooter";
import Sharing from "../Components/Sharing";
import {CreateMarkup} from "../Components/CreateMarkup";
import {isMobile, deviceDetect} from "react-device-detect";
import fbShareImg from "../Assets/images/fb-share.jpg";
import {isFbApp} from "../AppConfig";

const createStar = (total = 1, highlighted = false, list) => {
    for (let i = 0; i < total; ++i) {
        let className = 'icon-star';
        if (true === highlighted) {
            className += ' won';
        }
        list.push(<i key={`easy-star-${i}-${list.length + i}`} className={className}/>);
    }
};

const createList = (arr, quesScore, totalQuest, starsList) => {
    let success = false;
    for (let i = 0; i < totalQuest; ++i) {
        success = false;
        if (
            typeof arr.questions !== 'undefined'
            && typeof arr.questions[i] !== 'undefined'
            && typeof arr.questions[i].user_score !== 'undefined'
            && arr.questions[i].user_score > 0) {
            success = true;
        }
        createStar(quesScore, success, starsList);
    }
}

const sleep = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time)
    });
}

const defaultTimerInterval = 3;

export default class Quiz extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            isFetching: false,
            isSubmitting: false,
            showPopup: false,
            step: 1,
            timerInterval: defaultTimerInterval,
            selected_answers: [],
            completed_quiz: false,
            showReward: false,
            stars: [],
            userReward: {
                score: 0
            },
            user_score: {
                easy: {
                    questions: [],
                    total_scores: 0,
                },
                medium: {
                    questions: [],
                    total_scores: 0,
                },
                hard: {
                    questions: [],
                    total_scores: 0,
                },
            },
            question_result: {
                content: '',
                correct_answers: [],
            },
            question: {
                id: 0,
                title: '',
                is_multi_ans: false,
                language: 'ar',
                stars: 1,
                answers: [],
            },
        }
        this.init = this.init.bind(this);
        this.resetQuiz = this.resetQuiz.bind(this);
        this.updateState = this.updateState.bind(this);
        this.grabQuestion = this.grabQuestion.bind(this);
        this.onSelectAnswer = this.onSelectAnswer.bind(this);
        this.didSelectedAnswer = this.didSelectedAnswer.bind(this);
        this.onSubmitQuestion = this.onSubmitQuestion.bind(this);
        this.quizPopup = this.quizPopup.bind(this);
        this.resultPopup = this.resultPopup.bind(this);
        this.userScores = this.userScores.bind(this);
        this.stepId = this.stepId.bind(this);
        this.canGrapNextQuestion = this.canGrapNextQuestion.bind(this);
        this.initTimer = this.initTimer.bind(this);
        this.badge = this.badge.bind(this);
        this.answeredQuestions = this.answeredQuestions.bind(this);
        this.iniStars = this.iniStars.bind(this);
        this.updateStars = this.updateStars.bind(this);

        this.timer = false;
    }

    async componentDidMount() {

        if (false !== this.timer) {
            clearInterval(this.timer);
        }

        const iniStars = this.iniStars();
        await iniStars;

        const initRequest = this.init(false);

        await initRequest;
        const request = this.grabQuestion();
        await request
        request.then((response) => {
            this.setState({
                question: response.data.question,
                completed_quiz: response.data.completed_quiz,
                showReward: response.data.completed_quiz,
                userReward: response.data.reward,
                user_score: response.data.user_score,
            }, () => {
                this.updateStars().then(() => {
                    this.updateState({
                        ready: true,
                        isFetching: false,
                    })
                })
            })
        }).catch((thrown) => {
            const err = ErrorHandler(thrown);
            console.error('err', err);
        })
    }

    async init(reset) {

        return PostRequest('/auth/init', {
            tkn: window.selfLocalStorage.getItem('tkn'),
            reset: reset,
            player: this.props.player,
        }).then((response) => {
            window.selfLocalStorage.setItem('tkn', response.data.tkn)
            this.updateState({
                initialized: true,
                home: response.data.home,
                badges: response.data.badges,
                user_score: response.data.user_score,
                completed_quiz: response.data.completed_quiz,
            }).then(() => {
                this.updateStars();
            })
        });
    }

    async resetQuiz(e) {

        const iniStars = this.iniStars();
        await iniStars;

        this.init(true)
            .then(() => {
                this.updateState({
                    showPopup: false,
                    selected_answers: [],
                    completed_quiz: false,
                    showReward: false,
                    userReward: {
                        score: 0
                    },
                    user_score: {
                        easy: {
                            questions: [],
                            total_scores: 0,
                        },
                        medium: {
                            questions: [],
                            total_scores: 0,
                        },
                        hard: {
                            questions: [],
                            total_scores: 0,
                        },
                    },
                    question_result: {
                        content: '',
                        correct_answers: [],
                    },
                    question: {
                        id: 0,
                        title: '',
                        is_multi_ans: false,
                        language: 'ar',
                        stars: 1,
                        answers: [],
                    },
                })
                    .then(() => {
                        this.updateStars()
                            .then(() => {
                                this.grabQuestion()
                                    .then((response) => {
                                        this.updateState({
                                            showPopup: false,
                                            question_result: {},
                                            question: response.data.question,
                                        });
                                    });
                            });
                    })
            });
    }

    updateState(state) {
        return new Promise((resolve) => {
            this.setState(state, () => {
                resolve();
            });
        });
    }

    async grabQuestion() {
        const update = this.updateState({
            showPopup: false,
            isFetching: true
        });
        await update;

        const request = PostRequest('/quiz/get-question', {
            tkn: window.selfLocalStorage.getItem('tkn')
        }).then((response) => {
            this.updateState({timerInterval: defaultTimerInterval, isFetching: false})
                .then(() => {
                    this.updateStars();
                })
            return response;
        });
        return request;
    }

    onSelectAnswer(ansId, e) {
        let selectedAnswers = this.state.selected_answers || [];

        if (!this.state.question.is_multi_ans) {
            selectedAnswers = [];
        }
        const indx = selectedAnswers.indexOf(ansId);
        if (indx !== -1) {
            selectedAnswers.splice(indx, 1);
        } else {
            selectedAnswers.push(ansId);
        }

        this.updateState({
            selected_answers: selectedAnswers
        })
    }

    didSelectedAnswer(ansId) {
        return this.state.selected_answers.indexOf(ansId) !== -1;
    }

    componentWillUnmount() {
        if (false !== this.timer) {
            clearInterval(this.timer);
        }
    }

    initTimer() {
        if (false === this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            const inter = this.state.timerInterval - 1;
            if (inter <= 0) {
                clearInterval(this.timer);
                this.updateState({
                    timerInterval: 0,
                });
                return;
            }

            this.updateState({
                timerInterval: inter,
            })
        }, 1000);
    }

    onSubmitQuestion(e) {

        const data = {
            'ques_id': this.state.question.id,
            'ques_answers': this.state.selected_answers || [],
        };

        this.updateState({
            isSubmitting: true
        })
            .then(() => {
                PostRequest('/quiz/question/answer', data,)
                    .then((response) => {
                        this.initTimer();
                        this.updateState({
                            selected_answers: [],
                            question_result: response.data.question_result,
                            completed_quiz: response.data.completed_quiz,
                            user_score: response.data.user_score,
                            userReward: response.data.reward,
                        }).then(async () => {
                            this.updateStars()
                                .then(async () => {
                                    const delay = sleep(1000);
                                    await delay;

                                    this.updateState({
                                        isSubmitting: false,
                                        showPopup: true,
                                    });
                                });
                        });

                    }).catch((thrown) => {
                    console.log('thrown', thrown)
                });

            })
    }

    iniStars() {
        let stars = [];
        return new Promise((resolve) => {
            stars = [];
            for (let i = 0; i < 20; ++i) {
                stars.push({
                    animated: false,
                    className: 'icon-star',
                });
            }

            this.updateState({
                stars: stars,
            })
                .then(() => {
                    resolve(stars);
                })
        });
    }

    updateStars() {
        let stars = this.state.stars || [];

        let questions = this.answeredQuestions();

        return new Promise((resolve) => {
            let starIndex = 0;
            let totalScore = 0;

            for (let i = 0; i < questions.length; ++i) {
                if (questions[i].user_score) {
                    totalScore += parseInt(questions[i].user_score, 10);
                    for (let x = 0; x < questions[i].user_score; ++x) {
                        if (!stars[starIndex].animated) {
                            stars[starIndex].className += ' won animate';
                            stars[starIndex].animated = true;
                        }
                        ++starIndex;
                    }
                }

                // for (let x = 0; x < questions[i].question_score; ++x) {
                //     if (questions[i].user_score && !stars[starIndex].animated) {
                //         stars[starIndex].className += ' won animate';
                //         stars[starIndex].animated = true;
                //     }
                //     // ++starIndex;
                // }
            }
            console.log('totalScore',totalScore)
            resolve();
        });

    }

    canGrapNextQuestion() {
        return this.state.timerInterval < 1;
    }

    quizPopup() {
        let correctAnswers = [];
        const quesCorrectAnswers = this.state.question_result.correct_answers || [];
        const quesAnswers = this.state.question.answers || [];
        for (let i = 0; i < quesAnswers.length; ++i) {
            const answer = quesAnswers[i];
            let indx = quesCorrectAnswers.findIndex((ans) => {
                return ans.id === answer.id;
            });
            if (indx !== -1) {
                quesCorrectAnswers[indx].letter = answer.letter;
                correctAnswers.push(answer.letter);
            }
        }

        let titleClass = '';
        if (correctAnswers.length === 4){
            titleClass = 'small-title';
            correctAnswers = [
                this.props.locale.get('all_answers_are_correct')
            ];
        }

        correctAnswers = correctAnswers.join(', ');
        let btnClasses = 'popup__form-btn';
        if (!this.canGrapNextQuestion()) {
            btnClasses += ' not-clickable'
        }

        let label = this.state.completed_quiz ? this.props.locale.get('show_results') : this.props.locale.get('next_quest');

        return <Popup isVisible={this.state.showPopup && !this.state.showReward}>
            <PopupHead>
                <div className="popup__1--title">
                    {this.props.locale.get('the_correct_answer_is')}
                    <span className={`popup__1--title1 ${titleClass}`}>{correctAnswers}</span>
                </div>
            </PopupHead>

            <PopupBody>
                <div className="popup__group">
                    <div className="popup__1--body--desc">
                        {
                            this.state.question_result.ques_type !== 'scenario' ? CreateMarkup(this.state.question_result.content) : <div>
                                {
                                    quesCorrectAnswers.map((ans,index)=>{
                                        let prefix = <strong><br/>
                                            {this.props.locale.get('the_other_correct_answer_is')}
                                            ({ans.letter})
                                            :</strong>;
                                        if (0 === index) {
                                            prefix = <strong>
                                                {quesCorrectAnswers.length > 1 ? this.props.locale.get('this_is_one_of_the_correct_answers') : this.props.locale.get('this_is_the_correct_answer')}
                                                ({ans.letter}):</strong>;
                                        }
                                        return <span>
                                            {prefix} {CreateMarkup(ans.content)}
                                        </span>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </PopupBody>

            <PopupFooter>
                {
                    !this.canGrapNextQuestion() ? '' : <button type="button" className={btnClasses} onClick={(e) => {
                        e.preventDefault();
                        if (this.state.completed_quiz) {
                            this.updateStars()
                                .then(() => {
                                    this.updateState({
                                        showReward: true,
                                        timerInterval: defaultTimerInterval,
                                    }).then(() => {
                                    })
                                })
                            return false;
                        }

                        this.grabQuestion()
                            .then((response) => {
                                this.updateState({
                                    showPopup: false,
                                    question_result: {},
                                    question: response.data.question,
                                })
                                    .then(() => {
                                    })
                            })
                    }}>
                    <span
                        className="popup__form-btn__title">{`${label}`}</span>
                    </button>
                }

            </PopupFooter>
        </Popup>
    }

    resultPopup() {
        let btnClasses = 'popup__form-btn';

        if (!this.state.completed_quiz || !this.state.showReward) {
            return '';
        }

        return <Popup isVisible={this.state.showReward}>
            <PopupHead>
                <div className="popup__1--title">
                    {/*<div className="popup__1--close" data-close-popup="rewardPopUp">*/}
                    {/*    <i className="icon-close"/>*/}
                    {/*</div>*/}
                    <div>{this.props.locale.get('congratulations')}</div>
                    <div className="popup__1--title2">
                        <div>
                            {this.props.locale.get('quiz_final_result',{score:this.state.userReward.score})}
                        </div>
                    </div>
                </div>
            </PopupHead>
            <PopupBody>
                <div className="popup__group">
                    <div className="popup__1--body--img">
                        <img src={this.state.userReward.image} alt="" width={116}/>
                    </div>
                    <div className="popup__1--body--desc2">
                        {CreateMarkup(this.state.userReward.content)}
                    </div>
                    <div className="popup__1--body--desc2">
                        {this.props.locale.get('thank_you_for_taking_this_short_quiz_and_for_being_part_of_international_human_rights_day')}
                    </div>
                    <div className="popup__1--body--desc2">
                        {this.props.locale.get('share_your_achievement_and_challenge_your_friends')}
                    </div>
                </div>
                <div className="popup__group popup__group--images">
                    <div className="popup__1--body--img popup__group--img">
                        <img src={this.props.locale.language == 'ar' ? hrLogo : hrEnLogo} alt=""/>
                    </div>
                    <div className="popup__1--body--img popup__group--img">
                        <img src={unLogo} alt=""/>
                    </div>
                </div>
            </PopupBody>

            <PopupFooter>
                <div className="popup__1--footer-desc">
                    {this.props.locale.get('support_human_rights')}
                </div>
                {/*<Sharing fbShareImg={this.state.userReward.reward} sharingLink={this.state.userReward.share_link}/>*/}
                <Sharing userReward={this.state.userReward} locale={this.props.locale}/>

                {
                    this.state.userReward.score < 20 ?
                        <button onClick={this.resetQuiz} className={`popup__1--footer__link`}>{this.props.locale.get('try_again_to_get_more_points')}</button> : ''
                }

            </PopupFooter>
        </Popup>
    }

    answeredQuestions() {
        let questions = [];
        const fillQuestions = (object, total, questionScore) => {
            for (let i = 0; i < total; ++i) {
                let question = {
                    user_score: 0,
                    correct_answers: 0,
                    question_score: questionScore,
                };
                if (typeof object.questions[i] !== 'undefined') {
                    question = object.questions[i];
                }
                questions.push(question);
            }
        }

        fillQuestions(this.state.user_score.easy, 3, 1);
        fillQuestions(this.state.user_score.medium, 4, 2);
        fillQuestions(this.state.user_score.hard, 3, 3);
        return questions;
    }

    userScores() {
        let scores = this.state.stars;
        let chunk = [];
        chunk = scores.array_chunk(10);
        if (window.innerWidth <= 250) {
            chunk = scores.array_chunk(7);
        }

        return chunk;
    }

    stepId() {
        let step = 0;
        let levels = Object.keys(this.state.user_score);
        for (let i = 0; i < levels.length; ++i) {
            let level = this.state.user_score[levels[i]];
            step += level.questions.length;
        }
        return step + 1;
    }

    badge(width) {
        let total = 0;
        let scores = Object.values(this.state.user_score);
        for (let i = 0; i < scores.length; ++i) {
            if (typeof scores[i].total_scores !== 'undefined' && parseInt(scores[i].total_scores, 10) > 0) {
                total += parseInt(scores[i].total_scores, 10);
            }
        }

        let badge = false;

        if (total > 15) {
            badge = gold_badge;
        } else if (total >= 9 && total <= 15) {
            badge = silver_badge;
        } else if (total >= 3 && total <= 8) {
            badge = bronze_badge;
        }

        if (!badge) {
            return '';
        }
        return <img src={badge} alt="" width={width}/>;
    }

    render() {
        const canSubmit = this.state.selected_answers.length;
        return (
            <Fragment>
                {this.quizPopup()}
                {this.resultPopup()}
                <Page contentClassName={'mod'} locale={this.props.locale}>
                    <div className="row jcc reverse991">

                        {
                            !this.state.ready ? <LdsLoader scale={100} thick={10}/> : <Fragment>
                                <div className="col-xl-8 col-lg-9">
                                    {
                                        this.state.completed_quiz ? '' : (this.state.isFetching ?
                                            <LdsLoader scale={100} thick={10}/> : <div>

                                                <QuizQuestion question={this.state.question}
                                                              stepId={this.stepId()}
                                                              locale={this.props.locale}
                                                              onSelectAnswer={this.onSelectAnswer}
                                                              didSelectedAnswer={this.didSelectedAnswer}/>
                                                <div className="quiz_more center mt30">
                                                    <button type={'button'} onClick={this.onSubmitQuestion}
                                                            className={`main-btn big ${canSubmit ? '' : 'disabled'} ${this.state.isSubmitting ? ' is-loading ' : ''}`}
                                                            disabled={!canSubmit}
                                                    >
                                                        {
                                                            this.state.isSubmitting ? <LdsLoader scale={30} thick={3}
                                                                                                 color={`#006fb7`}/> : this.props.locale.get('next_quest')
                                                        }
                                                    </button>
                                                </div>
                                                <div className="quiz_num">
                                                    <div className="quiz_num__current">{this.stepId()}</div>
                                                    <div className="quiz_num__limit">/10</div>
                                                    {/*{`10/${this.stepId()}`}*/}
                                                </div>

                                            </div>)
                                    }
                                </div>
                                <div className="col-lg-3">
                                    <div className="progress_badge">
                                        <div className="progress">
                                            <div className="progress_bar">
                                                {
                                                    this.userScores().map((row, rowIndex) => (
                                                        <div className={`progress_bar__row`} key={`row-${rowIndex}`}>
                                                            {
                                                                row.map((star, index) => {
                                                                    return <i className={star.className}
                                                                              key={`row-star-${rowIndex}-${index}`}/>
                                                                })
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="badge">
                                            {this.badge(135)}
                                        </div>
                                    </div>

                                </div>
                            </Fragment>
                        }
                    </div>
                </Page>
            </Fragment>
        );
    }
}
